<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-outbound-email-transport', params: { accountId: this.$route.params.accountId } }">Outbound Email Transport</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ outboundEmailTransport.label }}</h1>
                <p class="text-caption text-center">Outbound email transport overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="outboundEmailTransport">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="teal darken-2" flat dark>
                        <v-toolbar-title>
                            Outbound Email Transport
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                        <p class="text-overline mb-0 mt-2">Label</p>
                        <p class="mb-0 pb-0">
                            {{ outboundEmailTransport.label }}
                            <v-btn icon color="teal darken-2" @click="editOutboundEmailTransportLabel">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            </v-btn>
                        </p>

                        <p class="text-overline mb-0 mt-8">Type</p>
                        <p class="mb-0 pb-0">
                            {{ typeLabel(outboundEmailTransport.type) }}
                            <v-btn icon color="teal darken-2" @click="editOutboundEmailTransportType">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            </v-btn>
                        </p>

                        <p class="text-overline mb-0 mt-8">
                            Settings
                            <v-btn icon color="teal darken-2" @click="isEditMode = !isEditMode">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            </v-btn>
                        </p>
                        <component :is="configComponent" v-model="editableOutboundEmailTransportConfig" :isEditMode="isEditMode" class="my-4"></component>
                        <template v-if="isEditMode">
                            <v-btn color="teal darken-2" @click="saveEditOutboundEmailTransportConfig" dark>Save</v-btn>
                            <v-btn color="teal darken-2" @click="isEditMode = false" dark outlined class="ml-2">Cancel</v-btn>
                        </template>

                        </v-card-text>
                        <!-- <p class="text-overline mb-0 mt-8">Email Replyto ID</p>
                        <p class="mb-0 pb-0">
                            {{ outboundEmailTransport.id }}
                        </p> -->

                        <!-- TODO: show how many domains are referencing thi soutboudn eamil transport -->
                        <!-- <p class="text-overline mb-0 mt-8">Status</p>
                        <p class="mb-0 pb-0">
                            <span v-if="affectedEmailTemplateList.length > 0">
                                This outbound-email-transport is referenced by {{ affectedEmailTemplateList.length }} templates.
                                < ! - - TODO: show the affectedEmailTemplateList - ->
                            </span>
                            <span v-if="affectedEmailTemplateList.length === 0">
                            This outbound-email-transport is not currently being used by any email templates.
                            </span>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Usage</p>
                        <p class="mb-0 pb-0">
                            {{ usage }}
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="checkOutboundEmailTransportUsage" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['far', 'sync-alt']"/>
                                    </v-btn>
                                </template>
                                Refresh
                            </v-tooltip>
                        </p> -->

                        <!-- <template v-if="outboundEmailTransport.website_id">
                        <p class="text-overline mb-0 mt-8">Website</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: outboundEmailTransport.website_id } }">
                                <span v-if="website">{{ website.label }}</span>
                                <span v-if="!website">Website</span>
                            </router-link>
                        </p>
                        </template> -->
                    </v-card>

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, outboundEmailTransportId: this.$route.params.outboundEmailTransportId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Outbound Email Transport Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.outboundEmailTransport, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Outbound Email Transport Config <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.editableOutboundEmailTransportConfig, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editOutboundEmailTransportLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email display name</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form @submit.prevent="saveEditOutboundEmailTransportLabel" class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The email display name is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <v-text-field v-model="editableOutboundEmailTransportLabel" label="Email display name"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditOutboundEmailTransportLabel" :disabled="!isEditOutboundEmailTransportDisplayNameComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editOutboundEmailTransportLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editOutboundEmailTransportTypeDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the outbound email transport type</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form @submit.prevent="saveEditOutboundEmailTransportType" class="px-2">
                    <v-row>
                    <v-col>
                        <!-- <p>
                            The email "from" address is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <p>
                            The domain you use in this "from" address must be the same one
                            that was set up for anti-spam tools.
                        </p> -->

                        <v-row no-gutters align="center">
                            <v-select outlined dense :items="editableOutboundEmailTransportTypeChoices" v-model="editableOutboundEmailTransportType" color="teal darken-2" label="Type" required hint="How to connect to the email transport to send emails">
                                <!-- <template #prepend>
                                    @
                                </template> -->
                                <!-- <template #append-outer>
                                    <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                        <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template> -->
                            </v-select>
                        </v-row>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditOutboundEmailTransportType" :disabled="!isEditOutboundEmailTransportTypeComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editOutboundEmailTransportTypeDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import None from '@/components/transport-config/None.vue';
import Local from '@/components/transport-config/Local.vue';
import Smtp from '@/components/transport-config/Smtp.vue';
// import OutboundEmailTransportList from '@/components/account-dashboard/OutboundEmailTransportList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // OutboundEmailTransportList,
        None,
        Local,
        Smtp,
    },
    data: () => ({
        affectedEmailTemplateList: [],

        error: null,
        account: null,
        outboundEmailTransport: null,
        invite: null,
        // edit form title dialog
        editOutboundEmailTransportTypeDialog: false,
        editOutboundEmailTransportLabelDialog: false,
        editableOutboundEmailTransportLabel: null,
        // editOutboundEmailTransportReplyToDialog: false,
        // editableOutboundEmailTransportReplyTo: null,

        editableOutboundEmailTransportType: null,
        editableOutboundEmailTransportTypeChoices: [], // will be loaded from server
        editableOutboundEmailTransportConfig: null,
        isEditMode: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.outboundEmailTransport !== null;
        },
        isEditOutboundEmailTransportTypeComplete() {
            return typeof this.editableOutboundEmailTransportType === 'string' && this.editableOutboundEmailTransportType.trim().length > 0;
        },
        isEditOutboundEmailTransportDisplayNameComplete() {
            return typeof this.editableOutboundEmailTransportLabel === 'string' && this.editableOutboundEmailTransportLabel.trim().length > 0;
        },
        // isEditOutboundEmailTransportReplyToComplete() {
        //     return typeof this.editableOutboundEmailTransportReplyTo === 'string' && this.editableOutboundEmailTransportReplyTo.trim().length > 0;
        // },
        configComponent() {
            switch (this.outboundEmailTransport.type) {
            case 'local':
                return 'Local';
            case 'smtp':
                return 'Smtp';
            default:
                return 'None';
            }
        },
    },
    methods: {
        typeLabel(type) {
            if (!Array.isArray(this.editableOutboundEmailTransportTypeChoices)) {
                return type;
            }
            const found = this.editableOutboundEmailTransportTypeChoices.find((item) => item.value === type);
            console.log(`typeLabel found? ${JSON.stringify(found)}`);
            if (found) {
                return found.text;
            }
            return type;
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadOutboundEmailTransport() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransport: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransport.get(this.$route.params.outboundEmailTransportId);
                console.log(`loadOutboundEmailTransport: response ${JSON.stringify(response)}`);
                if (response) {
                    this.outboundEmailTransport = response;
                    this.editableOutboundEmailTransportLabel = response.label;
                    this.editableOutboundEmailTransportType = response.type;
                    this.editableOutboundEmailTransportConfig = response.config ?? {};
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load outbound-email-transport');
                }
            } catch (err) {
                console.error('failed to load outbound-email-transport', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransport: false });
            }
        },
        async loadOutboundEmailTransportTypeList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransportType.search(); // optional: { q: 'search term' }
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.editableOutboundEmailTransportTypeChoices = response.list; // each item has { text, value, hint }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load outbound email transport type list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: false });
            }
        },
        async save(itemAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditOutboundEmailTransport: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransport.edit({ id: this.$route.params.outboundEmailTransportId }, itemAttr);
                console.log(`saveEditOutboundEmailTransport: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit outbound-email-transport' });
                return false;
            } catch (err) {
                console.error('failed to edit outbound-email-transport', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit outbound-email-transport' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditOutboundEmailTransport: false });
            }
        },
        editOutboundEmailTransportType() {
            this.editOutboundEmailTransportTypeDialog = true;
            this.editableOutboundEmailTransportType = this.outboundEmailTransport.type;
        },
        editOutboundEmailTransportLabel() {
            this.editOutboundEmailTransportLabelDialog = true;
            this.editableOutboundEmailTransportLabel = this.outboundEmailTransport.label;
        },
        async saveEditOutboundEmailTransportType() {
            const isEdited = await this.save({ type: this.editableOutboundEmailTransportType });
            if (isEdited) {
                this.editOutboundEmailTransportTypeDialog = false;
                this.$set(this.outboundEmailTransport, 'type', this.editableOutboundEmailTransportType);
                this.editableOutboundEmailTransportConfig = {}; // old config doesn't apply to new type, it would cause us to send parameters that are not valid for the new type and the server will reject the request even if the other parameters are fine
                this.isEditMode = true; // if user changed type they probably need to edit the settings also
            }
        },
        async saveEditOutboundEmailTransportLabel() {
            const isEdited = await this.save({ label: this.editableOutboundEmailTransportLabel });
            if (isEdited) {
                this.editOutboundEmailTransportLabelDialog = false;
                this.$set(this.outboundEmailTransport, 'label', this.editableOutboundEmailTransportLabel);
            }
        },
        async saveEditOutboundEmailTransportConfig() {
            const isEdited = await this.save({ config: this.editableOutboundEmailTransportConfig });
            if (isEdited) {
                // this.editOutboundEmailTransportLabelDialog = false;
                this.isEditMode = false;
                this.$set(this.outboundEmailTransport, 'config', this.editableOutboundEmailTransportConfig);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadOutboundEmailTransport();
        this.loadOutboundEmailTransportTypeList();
    },
};
</script>
